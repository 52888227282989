import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../../Common/Header/Header";
import AICounsellor from "./DashboardRightSection/AICounsellor/AICounsellor";

function Dashboard(){
    return(
        <>
        <div className="sticky-header"><Header /></div>
        <div className="main-page">
            <Outlet />
        </div>
        <div className="aichat-position"><AICounsellor></AICounsellor></div>
        </>
    )
}
export default Dashboard;