import axiosInstanceAI from "./AIInterceptor";

const AIServise = {
    Aichat: async (data) => {
    try {
      const response = await axiosInstanceAI.post('/chatWithBot', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  createSession: async (data) => {
    try {
      const response = await axiosInstanceAI.post('/createSession', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  refreshSession: async (data) => {
    try {
      const response = await axiosInstanceAI.post('/refreshSession', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },


}
export default AIServise