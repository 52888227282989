import React, { useState } from "react";
import DashboardMiddleSection from "../DashboardMiddleSection/DashboardMiddleSection";
import Progress from "../DashboardLeftSection/progress/Progress.jsx";
import RecentlyViewed from "../DashboardLeftSection/RecentlyViewed/RecentlyViewed.jsx";
import Groups from "../DashboardLeftSection/Groups/Groups.jsx";
import styles from "./DashboardPage.module.css";
import Notification from "../DashboardRightSection/Notification/Notification.jsx";
import Network from "../DashboardRightSection/Network/Network.jsx";
// import ChatWindow from "../DashboardRightSection/AICounsellor/AICounsellor.jsx";
import { useInput } from "../DashboardSearch/GlobalSearch.jsx";
import Programs from "../../../../Common/Programs/Programs.jsx";
import InstitutesCard from "../../../../Common/InstitutesCard/InstitutesCard.jsx";
import companyLogoOne from "../../../../../Assets/Logo/comp-logo-1.svg";
import companyLogoTwo from "../../../../../Assets/Logo/comp-logo-2.svg";
import ProfileCard from "../../User_Account/Connections/SubComponents/ProfileCard.jsx";
import brochure from "../../../../../Assets/Images/msm-brochure.jpg";
const programs = [
  {
      id: 1,
      university: "Douglas College"
  },
  {
      id: 2,
      university: "BCIT"
  },
  {
      id: 3,
      university: "Alexander College"
  },
  {
      id: 4,
      university: "Simon Frasier"
  },
  {
      id: 5,
      university: "Douglas College"
  },
  {
      id: 6,
      university: "Douglas College"
  },
];
const institutesData = [
  {
    id: 1,
    name: "Douglas College",
    landmark: "New Westminster,",
    location: "British Columbia, Canada",
    img: companyLogoOne,
    backgroundColor: "#000000",
    ranked: 1,
    intake: {
      startDate: "Sep 2024",
      endDate: "Jan 2025",
      duration: "2 Years",
    },
    degree: "Bachelors Degree",
    degreeFee: "CAD $14,110/Year",
    applicationFee: "CAD $30",
    graduates: "403 of 435 graduated",
    graduationLikelihood: "95%",
    description:
      "Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program. Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...",
  },
  {
    id: 2,
    name: "Simon Frasier",
    img: companyLogoTwo,
    backgroundColor: "rgba(171, 26, 45, 1)",
    landmark: "Burnaby,",
    location: "British Columbia, Canada",
    ranked: 4,
    intake: {
      startDate: "Sep 2024",
      endDate: "Jan 2025",
      duration: "4 Years",
    },
    degree: "Bachelors Degree",
    degreeFee: "CAD $14,110/Year",
    applicationFee: "CAD $30",
    graduates: "403 of 435 graduated",
    graduationLikelihood: "95%",
    description:
      "Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program. Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...",
  },
];

function DashboardPage() {
  const [profiles, setProfiles] = useState([
    {
      id: 1,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+1",
      imgSrc: "https://randomuser.me/api/portraits/men/1.jpg",
      name: "Edward Locke",
      description: "Getting a degree",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/men/2.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/women/1.jpg", name: "Connection 2" },
      ],
    },
    {
      id: 2,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+2",
      imgSrc: "https://randomuser.me/api/portraits/women/2.jpg",
      name: "Carrie Thomas",
      description: "Just trying to figure it out",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/men/3.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/women/2.jpg", name: "Connection 2" },
        { imgSrc: "https://randomuser.me/api/portraits/women/3.jpg", name: "Connection 3" },
      ],
    },
    {
      id: 3,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+3",
      imgSrc: "https://randomuser.me/api/portraits/men/4.jpg",
      name: "Lynn Hobbs",
      description: "IT Specialist at Douglas College",
      connections: [{ imgSrc: "https://randomuser.me/api/portraits/men/5.jpg", name: "Connection 1" }],
    },
    {
      id: 4,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+4",
      imgSrc: "https://randomuser.me/api/portraits/men/6.jpg",
      name: "Richard Jaffe",
      description: "Geology student",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/women/4.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/men/7.jpg", name: "Connection 2" },
      ],
    },
    {
      id: 5,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+5",
      imgSrc: "https://randomuser.me/api/portraits/women/5.jpg",
      name: "Angel Brown",
      description: "French language student",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/men/8.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/women/6.jpg", name: "Connection 2" },
        { imgSrc: "https://randomuser.me/api/portraits/women/7.jpg", name: "Connection 3" },
      ],
    },
    {
      id: 6,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+6",
      imgSrc: "https://randomuser.me/api/portraits/men/9.jpg",
      name: "Jon Smith",
      description: "Restaurant Management",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/men/10.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/women/8.jpg", name: "Connection 2" },
      ],
    },
    {
      id: 7,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+7",
      imgSrc: "https://randomuser.me/api/portraits/men/11.jpg",
      name: "Phillip Christopher",
      description: "Studying mathematics",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/men/12.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/women/9.jpg", name: "Connection 2" },
      ],
    },
    {
      id: 8,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+8",
      imgSrc: "https://randomuser.me/api/portraits/women/10.jpg",
      name: "Sophie Turner",
      description: "Photographer",
      connections: [{ imgSrc: "https://randomuser.me/api/portraits/men/13.jpg", name: "Connection 1" }],
    },
    {
      id: 9,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+9",
      imgSrc: "https://randomuser.me/api/portraits/men/14.jpg",
      name: "David Wilson",
      description: "Music producer",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/women/11.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/men/15.jpg", name: "Connection 2" },
      ],
    },
    {
      id: 10,
      backgroundImage: "https://via.placeholder.com/600x200?text=Background+10",
      imgSrc: "https://randomuser.me/api/portraits/women/12.jpg",
      name: "Emma Stone",
      description: "Art student",
      connections: [
        { imgSrc: "https://randomuser.me/api/portraits/men/16.jpg", name: "Connection 1" },
        { imgSrc: "https://randomuser.me/api/portraits/women/13.jpg", name: "Connection 2" },
      ],
    },
  ]);
  const { inputValue } = useInput();
  return (
    <div className="row">
      <div className={`${styles['oreder-chnages-left']} dashboard-section col-md-12 col-lg-3`}>
        <div className={styles['sticy-header']}>
        <div className={styles.leftContainer}>
          {/* <Notification />
          <RecentlyViewed /> */}
          <Network />
          <Groups />
        </div>
        </div>
      </div>
      <div className={`${styles['oreder-chnages']} col-md-12 col-lg-6`}>
        
        {inputValue && (
          <div>
            <p className={styles['result-text']}>Result:</p>
            <div>
              <p className={styles['sub-heading-text']}>Programs:</p>
              <div className="row">
              {programs.map((program) => (
                 <div className="col-md-6">
                  <div className={styles["main_card"]}  key={program.id}>
                    <Programs key={program.id} program={program}/>
                  </div>
                </div>
             ))}
          </div>
            </div>
            <br></br>
            <div>
              <p className={styles['sub-heading-text']}>Institutes:</p>
              <div className="row">
                  {institutesData.map((institute) => (
                  <div className="col-md-6">
                      <InstitutesCard key={institute.id} institute={institute}/>
                  </div>
                ))}
              </div>
              </div>
              <br></br>
              <div>
                <p className={styles['sub-heading-text']}>Peoples:</p>
                <div className="row">
                {profiles.map((profile) => (
                  <div className="col-md-6 col-sm-12  mb-3">
                 
          <ProfileCard 
            key={profile.id}
            backgroundImage={profile.backgroundImage}
            imgSrc={profile.imgSrc}
            name={profile.name}
            description={profile.description}
            connections={profile.connections}
            buttonShow={true}
          />
          </div>
         
        ))}
         </div>
                </div>
          </div>

        )}

        {!inputValue && (
          <DashboardMiddleSection />
        )}
        
      </div>
      <div className={`${styles['oreder-chnages-right']} dashboard-section col-md-12 col-lg-3`}>
        <div className={`${styles.rightContainer} ${styles['sticy-header1']}`}>
          <img src={brochure} className={styles["marketing-brochure"]} alt="brochure" />
          {/* <ChatWindow /> */}
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
