
import classes from "./ProgramDetailPage.module.css";
import React, {useState,useEffect,useRef}from "react";
import program_details_image from "../../../../Assets/Images/program_details_image.png";
import heartIcon from "../../../../Assets/Logo/heartIcon.svg";
import LevelOfEducationIcon from "../../../../Assets/Logo/level of Education.svg";
import durationIcon from "../../../../Assets/Logo/duration.svg";
import costOfLivingIcon from "../../../../Assets/Logo/cost of living.svg";
import tutionFeeIcon from "../../../../Assets/Logo/Tuition Fee.svg";
import applicationFeeIcon from "../../../../Assets/Logo/Application fee.svg";
import downArrow from "../../../../Assets/Logo/down.arrow.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import {toast} from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import useLocalStorage from "../../../GlobleShared/CustomHooks/useLocalStorage";
import ProgramSearchService from "../../../../Services/ProgramSearchServices";
import ApplicationService from "../../../../Services/ApplicationServices";

function ProgramDetailPage() {


  const [userDetail] = useLocalStorage('userDetail');
  const studentId=userDetail?.refId
  const { programId } = useParams();
  const { institutionId } = useParams();
  const {selectedProgramTypeIds} = useParams();
  const {selectedCountryId} = useParams();
  const [programDetails, setProgramDetails] = useState(null);
  const [programContactList, setProgramContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [otherProgramsList, setOtherProgramsList] = useState([]);
  const navigate = useNavigate();
  const [userDetails] = useLocalStorage("userDetails");
  const initial = useRef(null);
  const blobURL = process.env.REACT_APP_LOGO_PATH;
    
    const imageSrc = programDetails?.InstLogoPath
    ? blobURL + programDetails.InstLogoPath
    : program_details_image;


    const [isReadMore, setIsReadMore] = useState(true);
    const [start, setStart] = useState(0)
    const [loadmore, setLoadmore] = useState("Load more")
    const [currency, setCurrency] = useState()
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState()


  
    const handleApplyClick = (data) => {
        const programTypeId = programDetails.ProgramType === "On-Campus" ? 1 : 2;
          navigate("/member/application/add/"+programTypeId +"?studentId=" +studentId +'&programId='+ data?.ProgramId);
    };

   
    const handleMoreProgram = (programId) => {
        const programDetailUrl = `/member/programs/detailPage/${programId}`;
        window.open(programDetailUrl, '_blank');
      };
  
    const fetchProgramDetails = async (programId) => {
      setIsLoading(true);
      ProgramSearchService.getApplicationProgramDetails(programId)
      .then((res) => {
       setProgramDetails(res);
       searchProgramForAgentByFilterNew(programId, res?.InstitutionId, res?.InstCountryId)
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
    };
    // useEffect(() => {
    //   if (updateprogramId) {
    //     searchProgramForAgentByFilterNew(updateprogramId);
    //   }
    // }, [updateprogramId]);
  
    const searchProgramForAgentByFilterNew = (programId, InstitutionId, NationalityId) => {
        
      setIsLoading(true);
    
      const payload = 
        {
          Nationality: NationalityId,
          PartnerTypeId: "",
          param1: "",
          param2: "",
          InstitutionId: InstitutionId,
          ExceptProgramId: String(programId || ""),
          IsProgramLevelWise: true,
          ProgramType: 0,
        };
  
      ProgramSearchService.searchProgramForAgentByFilterNew(payload)
        .then((res) => {
          if (res){
            // debugger
            res.map((item) => {
                item.end = 4;
              });
            setOtherProgramsList(res);
            console.log(otherProgramsList);
            console.log(res);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.message);
        });
      };


  
    useEffect(() => {
      if(!initial.current){
        initial.current = true;
      fetchProgramDetails(programId);
      }
    }, [programId]);

  
    const checkApplicationFeePresent = () => {
      
      return false;
    };
  
    const getFeeDetail = (feeType, field) => {
        const fee = programDetails?.FeeDetail?.find((detail) => detail.FeeType === feeType);
        return fee ? fee[field] : "N/A";
      };
    
      const getConvertedFee = (feeType, field) => {
        const fee = programDetails?.FeeDetail?.find((detail) => detail.FeeType === feeType);
        if (!fee || !currencyExchangeRate || !currencyExchangeRate[programDetails?.ProgramCurrency]) return "N/A";
        const rate = currencyExchangeRate[programDetails?.ProgramCurrency]?.value;
        const amount = fee[field];
        return rate ? (amount / rate).toFixed(2) : "N/A";
      };

   
    const toggleReadMore = () => {
      setIsReadMore((prev) => !prev);
    };
  
    const loadMore = (programlevelid) => {
        setOtherProgramsList((prevList) =>
          prevList.map((item) => {
            if (item.ProgramLevelId === programlevelid) {
              const updatedEnd = item.end + 4;
              const isNoMoreResults = updatedEnd >= item.programs.length;
              return {
                ...item,
                end: updatedEnd,
                noMoreResults: isNoMoreResults,
              };
            }
            return item;
          })
        );
      };
      


    return (
      <div className={`${classes["new-design"]} ${classes["new-agent-design"]}`}>
        <div className={`${classes["grey-card"]} ${classes["bor-r-35"]}`}>
          <div className={`${classes["card"]} ${classes["box-shadow-none"]} ${classes["bor-r-35"]}`}>
            <div className={`${classes["card-body"]} p-0`}>
              <div className={`${classes["title-data"]} ${classes["d-flex"]} `}>
                <h4 className={`${classes["tittle"]}  mb-0"`}>
                  {programDetails?.ProgramName}
                </h4>
                <p className="my-auto ml-1">
                  By {programDetails?.InstName} 
                  {/* - {programDetails?.AgentType} */}
                </p>
              </div>
              <hr />
              <div className="row">
               <div className={`${classes["programDetails_left_section"]} col-md-8 d-flex align-items-stretch flex-column`}>
                    <label className={`${classes["prgTitle"]}`}>About the Program</label>
                    <div className="mb-3">
                        <div
                        dangerouslySetInnerHTML={{ __html: programDetails?.Description }}
                        className={isReadMore ? classes["limitTextHeight"] : ""}
                        />
                        {programDetails?.Description?.length > 300 && (
                        <a onClick={toggleReadMore} className={`${classes["redTxt"]}`} style={{ lineHeight: "70px" }}>
                            {isReadMore ? "Read more" : "Read less"}
                        </a>
                        )}
                    </div>

                    <label className={`${classes["prgTitle"]} mt-3`}>Subject Requirements</label>
                    {programDetails?.SubjectRequirement?.length === 0 ? (
                        <p className={`${classes["prgbody"]}`}>N/A</p>
                    ) : (
                        <ul>
                        {programDetails?.SubjectRequirement.map((subject, index) => (
                            <p key={index} className={`${classes["prgbody"]}`}>
                            <li className={`${classes["prgli"]}`}>
                                {subject.ReqName} {subject.Score}
                            </li>
                            </p>
                        ))}
                        </ul>
                    )}

                    <label className={`${classes["prgTitle"]} mt-3`}>Academic Requirements</label>
                    {programDetails?.AccadmicRequirement?.length === 0 ? (
                        <p className={`${classes["prgbody"]}`}>N/A</p>
                    ) : (
                        <ul>
                        {programDetails?.AccadmicRequirement.map((academic, index) => (
                            <p key={index} className={`${classes["prgbody"]}`}>
                            <li className={`${classes["prgli"]}`}>
                                {academic.ReqName} {academic.Score}
                            </li>
                            </p>
                        ))}
                        </ul>
                    )}

                    <label className={classes["prgTitle"]} style={{ marginTop: "4px" }}>
                        English Language Requirements
                    </label>
                    {programDetails?.EnglishRequirement?.length === 0 ? (
                        <p className={`${classes["prgbody"]}`}>Not Required</p>
                    ) : (
                        <ul>
                        {programDetails?.EnglishRequirement.map((eng, index) => (
                            <p key={index} className={classes["prgbody"]}>
                            <li className={`${classes["prgli"]}`}>
                                {eng.ReqName} - overall score of {eng.Score}
                            </li>
                            </p>
                        ))}
                        </ul>
                    )}

                    <div className="d-flex bd-highlight prgbttm my-4">
                        <div className="pt-2 pr-2 pb-2 flex-fill bd-highlight">
                        {/* {programDetails?.EnglishRequirement?.length !== 0 && (
                            <button
                            type="button"
                            className={`${classes["btn-bor-blue"]}`}
                            onClick={() => console.log("Navigate to", programDetails?.ProgramId)}
                            >
                            Check students eligibility
                            </button>
                        )} */}
                        {/* {programDetails?.EnglishRequirement?.length === 0 &&
                            programDetails?.Inteks?.length > 0 && ( */}
                            <button
                                type="button"
                                className={`${classes["btn-agent-blue"]}`}
                                onClick={()=>handleApplyClick(programDetails)}>
                                Apply
                            </button>
                            {/* )} */}
                        </div>
                    </div>

                    {programDetails?.AdditionalRequirement?.length > 0 && (
                        <>
                        <label className={`${classes["prgTitle"]}`}>Additional Requirement</label>
                        {programDetails?.AdditionalRequirement.map((addreq, index) => (
                            <div key={index} className="d-flex">
                            <ul>
                                <li>
                                <span
                                    className={`${classes["additional_req"]} d-flex flex-wrap`}
                                    dangerouslySetInnerHTML={{ __html: addreq.AdditionalReq }}
                                />
                                </li>
                            </ul>
                            </div>
                        ))}
                        </>
                    )}

                    {programDetails?.DocumentRequirement?.length > 0 && (
                        <>
                        <label className={`${classes["prgTitle"]} mt-3`}>Documents Required</label>
                        <ul>
                            {programDetails?.DocumentRequirement.map((docreq, index) => (
                            <p key={index} className={`${classes["prgbody"]}`}>
                                <li>{docreq.DocumentName}</li>
                            </p>
                            ))}
                        </ul>
                        </>
                    )}
                    </div>
  
              <div className="col-md-4">
                <div className={`mb-3 ${classes["prgdtls"]}`}>
                    <div className="card text-center" style={{ boxShadow: "none" , backgroundColor:"#FBF7F2"}}>
                    <div className={`card-header ${classes["card-header"]}`}>
                        <img
                        src={`${blobURL+ programDetails?.InstLogoPath || ""}`}
                        className={classes["institution-detail-profile-img"]}
                        alt=""
                        />
                    </div>
                    <hr className={classes["hrline"]} />
                    <div className={`card-body p-0 ${classes["card-body"]}`}>
                        <div className={`px-3 ${classes["body-content"]}`}>
                        {/* Application Fee */}
                        {programDetails?.FeeDetail?.length > 0 && (
                            <div className="d-flex bd-highlight">
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                <p className={`d-flex gap-1 ${classes["InstSubTitle"]} ${classes["InstSubText"]}`}>
                                <img className="px-2" src={applicationFeeIcon} alt="" className="mx-2" width="17" />
                                Application Fee
                                </p>
                            </div>
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst2"]}`}>
                                <p className={`${classes["InstSubTitle"]} ${classes["InstSubTextValue"]}`}>
                                {programDetails?.ProgramCurrency === currency || !currencyExchangeRate ? (
                                    <>
                                     {programDetails?.ProgramCurrency}{" "}
                                    <span className={`mx-1 ${classes[" text-gray"]} ${classes["strike-center"]}`}>
                                        {getFeeDetail("Application Fee", "FeeAmount")}
                                    </span>
                                    {getFeeDetail("Application Fee", "ActualFee")}
                                    </>
                                ) : (
                                    <>
                                     {programDetails?.ProgramCurrency}{" "}
                                    <span className={` text-gray mx-1 ${classes[" text-gray"]} ${classes["strike-center"]}`}>
                                        {getConvertedFee("Application Fee", "FeeAmount")}
                                    </span>
                                    {getConvertedFee("Application Fee", "ActualFee")}
                                    </>
                                )}
                                </p>
                            </div>
                            </div>
                        )}

                        {/* Tuition Fee */}
                        {programDetails?.FeeDetail?.length > 0 && (
                            <div className="d-flex bd-highlight">
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                <p className={`d-flex gap-1  ${classes["InstSubTitle"]} ${classes["InstSubText"]}`}>
                                <img src={tutionFeeIcon} alt="" className="mx-2 " width="17" />
                                Tuition Fee ({getFeeDetail("Tuition Fee", "FeeBasis")})
                                </p>
                            </div>
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst2"]}`}>
                                <p className={`${classes["InstSubTitle"]} ${classes["InstSubTextValue"]}`}>
                                {programDetails?.ProgramCurrency === currency || !currencyExchangeRate ? (
                                    <>
                                    {programDetails?.ProgramCurrency}{" "}
                                    {getFeeDetail("Tuition Fee", "FeeAmount")}
                                    </>
                                ) : (
                                    getConvertedFee("Tuition Fee", "FeeAmount")
                                )}
                                </p>
                            </div>
                            </div>
                        )}

                        {/* Cost of Living */}
                        {programDetails?.InstCostofLiving_Year && (
                            <div className="d-flex bd-highlight">
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                <p className={`d-flex  gap-1  ${classes["InstSubTitle"]} ${classes["InstSubText"]}`}>
                                <img src={costOfLivingIcon} alt="" className="mx-2" width="17" />
                                Cost of living per year
                                </p>
                            </div>
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst2"]}`}>
                                <p className={`${classes["InstSubTitle"]} ${classes["InstSubTextValue"]}`}>
                                {programDetails?.ProgramCurrency === currency || !currencyExchangeRate ? (
                                    `${programDetails?.ProgramCurrency} ${programDetails?.InstCostofLiving_Year || "N/A"}`
                                ) : (
                                    `${currency} ${(programDetails?.InstCostofLiving_Year /
                                    currencyExchangeRate[programDetails?.ProgramCurrency]?.value).toFixed(2)}`
                                )}
                                </p>
                            </div>
                            </div>
                        )}

                        {/* Duration */}
                        <div className="d-flex bd-highlight">
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst1"]}`}>
                            <p className={`d-flex gap-1  ${classes["InstSubTitle"]} ${classes["InstSubText"]}`}>
                                <img className="" src={durationIcon} alt="" className="mx-2" width="17" />
                                Duration
                            </p>
                            </div>
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst2"]}`}>
                            <p className={`${classes["InstSubTitle"]} ${classes["InstSubTextValue"]}`}>
                                {programDetails?.DurationTime || "N/A"}
                            </p>
                            </div>
                        </div>

                        {/* Level of Education */}
                        <div className="d-flex bd-highlight">
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst1"]}`}>
                            <p className={`d-flex  gap-1  ${classes["InstSubTitle"]} ${classes["InstSubText"]}`}>
                                <img src={LevelOfEducationIcon} alt="" className="mx-2" width="17" />
                                Level of Education
                            </p>
                            </div>
                            <div className={`py-2 flex-fill bd-highlight ${classes["subInst2"]}`}>
                            <p className={`${classes["InstSubTitle"]} ${classes["InstSubTextValue"]}`}>
                                {programDetails?.LevelOfEducation || "N/A"}
                            </p>
                            </div>
                        </div>
                        </div>
                        <hr />
                       {/* Starting Date and Deadlines */}
                    <div className={`px-3 ${classes["starting-deadlines"]}`}>
                    <div className="row">
                        <div className="col-6">
                        <div className={`${classes["SubTitle"]} ${classes["InstSubTextValue"]}`}>Starting Date</div>
                        </div>
                        <div className="col-6">
                        <div className={`${classes["SubTitle"]} ${classes["InstSubTextValue"]} text-center`}>Submission Deadlines</div>
                        </div>
                    </div>
                    {programDetails?.Inteks?.length === 0 ? (
                        <div className="row">
                        <div className="col-12">
                            <p className={classes["InstSubTitle"]}>N/A</p>
                        </div>
                        </div>
                    ) : (
                        programDetails?.Inteks.map((item, index) => (
                        <div key={index} className="row my-1">
                            <div className="col-6">
                            <div className="d-flex">
                                <div className={`${classes["InstSubTitle"]} ${classes["redSubTitle"]} text-red fz-10 text-nowrap`}>
                                {item?.IntakeName} -
                                </div>
                                <div className={classes["InstSubTitleStatus"]}>{item?.IntekStatus}</div>
                            </div>
                            </div>
                            <div className="col-6">
                            <div className={`${classes["InstSubTitle"]} ${classes["redSubTitle"]} text-red fz-10`}>
                                {item?.IntekDate}
                            </div>
                            </div>
                        </div>
                        ))
                    )}
                    </div>
                    {programContactList?.length > 0 && (
                            <div className="card-footer" style={{ margin: 0 }}>
                                <div className="contactBox">
                                <h4 className="contactHeading">Contact Details</h4>
                                <div className="contact-list">
                                    <ol>
                                    {programContactList.map((contact, index) => (
                                        <li key={index}>
                                        <div className="contact-list-inner">
                                            <h5>{contact?.Name}</h5>
                                            <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex text-break align-items-center">
                                                <img
                                                    src="assets/icons/email.svg"
                                                    alt=""
                                                    className="mr-2"
                                                    width="15"
                                                />
                                                <span className="text-truncate">
                                                    {contact?.Email}
                                                </span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </li>
                                    ))}
                                    </ol>
                                </div>
                                </div>
                            </div>
                            )}

                    </div>
                    </div>
                </div>
                </div>

              </div>

               {/* Program Offerings */}
                    {otherProgramsList?.length > 0 && (
                    <div>
                        <label className={`${classes["prgTitle"]} m-2`}>Program Offerings</label>
                        {otherProgramsList.map((item, index) => (
                        <div key={index}>
                            {item?.programs?.length > 0 && (
                            <label className={`${classes["prgLevel"]} m-2`}>{item?.ProgramLevel}</label>
                            )}
                            <div className="row">
                            {item?.programs?.slice(start, item?.end).map((program, idx) => (
                                <div key={idx} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div className={`card ${classes["crd"]} px-3`}>
                                    <div className={`d-flex ${classes["icon-position"]}`}>
                                    <div
                                        className={`${classes["card-title"]} text-truncate mb-0 px-2`}
                                        title={program?.ProgramName}
                                    >
                                        {program?.ProgramName}
                                    </div>
                                    {/* <div className={classes["fav-image"]}>
                                        <AppCommonFavoriteIcon
                                        addProgram={(event) =>
                                            favorite_selectProgram(event, program.ProgramId)
                                        }
                                        isSelected={program.IsShortList}
                                        programId={program.ProgramId}
                                        currentUser={currentUser}
                                        />
                                    </div> */}
                                    </div>
                                    <div
                                    className={`d-flex bd-highlight mainInst align-items-center px-2`}
                                    >
                                    <div className={`${classes["institute-logo"]} pr-2 bd-highlight `}>
                                        <img
                                        // src={`${filepath}${program?.Institution[0]?.InstLogoPath}`}
                                        src={`${blobURL+program?.Institution[0]?.InstLogoPath}`}
                                        alt={
                                            blobURL+program?.Institution[0]?.InstLogoPath ? "" : "avatar"
                                        }
                                        />
                                    </div>
                                    <div className={`${classes["institute-name"]} pl-2 bd-highlight `}>
                                        <p
                                        className={`${classes["instName"]} font-poppins text-56 text-truncate mb-0`}
                                        title={program?.Institution[0]?.InstName}
                                        >
                                        {program?.Institution[0]?.InstName}
                                        </p>
                                        <p
                                        className={`${classes["subName"]} text-56 text-truncate`}
                                        title={`${program?.Institution[0]?.InstCity || ""}${
                                            program?.Institution[0]?.InstCity ? ", " : ""
                                        }${program?.Institution[0]?.InstCountry}`}
                                        >
                                        {program?.Institution[0]?.InstCity
                                            ? `${program?.Institution[0]?.InstCity}, `
                                            : ""}
                                        {program?.Institution[0]?.InstCountry}
                                        </p>
                                    </div>
                                    </div>
                                    <hr className="mb-2" />
                                    <div className="card-body">
                                    <div className={`d-flex bd-highlight  ${classes["subInst"]}`}>
                                        {program?.DurationTime && (
                                        <div className= {`px-2 pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                            <p className={classes["InstSubTitle"]}>Duration</p>
                                            <p className={classes["Instsub"]}>
                                            {program?.DurationTime}
                                            </p>
                                        </div>
                                        )}
                                        {program?.ProgramLevel && (
                                        <div className= {`px-2 pb-2 pt-0 flex-fill bd-highlight ${classes["subInst2"]}`}>
                                            <p className={classes["InstSubTitle"]}>
                                            Level of Education
                                            </p>
                                            <p className={classes["Instsub"]}>
                                            {program?.ProgramLevel}
                                            </p>
                                        </div>
                                        )}
                                    </div>
                                    <div className={`d-flex bd-highlight  ${classes["subInst"]}`}>
                                        <div className={`px-2 pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                        <p className={classes["InstSubTitle"]}>Intakes</p>
                                        {program?.Intakes?.length > 0 ? (
                                            program?.Intakes.map((intake, i) => (
                                            <p key={i} className={classes["Instsub"]}>
                                                {`${intake.IntakeName} : ${intake.StatusName}`}
                                            </p>
                                            ))
                                        ) : (
                                            <p className={classes["Instsub"]}>
                                            No intake available
                                            </p>
                                        )}
                                        </div>
                                        {program?.FeeDetail?.length > 0 && (
                                        <div className={`px-2 pb-2 pt-0 flex-fill bd-highlight ${classes["subInst2"]}`}>
                                            <p className={classes["InstSubTitle"]}>Fees</p>
                                            {program?.FeeDetail.map((feeItem, j) => (
                                            <p key={j} className={classes["Instsub"]}>
                                                {`${feeItem.FeeType} : ${program?.ProgramCurrency} ${feeItem.FeeAmount}`}
                                            </p>
                                            ))}
                                        </div>
                                        )}
                                         {program?.ScholarShipAmtType !== null && (
                                            <div className={`px-2 pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                            <p className={`text-56 ${classes["InstSubTitle"]}`}>Scholarship</p>
                                            {program?.ScholarShipAmtType === 0 && (
                                                <p className={` font-weight-bold mt-1 ${classes["waiverPercentage"]}`}>
                                                {program?.ScholarShipMinAmt} to {program?.ScholarShipMaxAmt}% Off
                                                </p>
                                            )}
                                            {program?.ScholarShipAmtType === 1 && (
                                                <p className={` font-weight-bold mt-1 ${classes["waiverPercentage"]}`}>
                                                {program?.ProgramCurrency} {program?.ScholarShipMinAmt} to {program?.ProgramCurrency} {program?.ScholarShipMaxAmt}
                                                </p>
                                            )}
                                            </div>
                                        )}
                                    </div>
                                    {program?.WaiverPer && (
                                        <div className={`d-flex bd-highlight  ${classes["subInst"]}`}>
                                        <div className={`px-2 pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                            <p className={classes["InstSubTitle"]}>
                                            Application Fee Waivers
                                            </p>
                                            <span className={`${classes["strike-center"]} ${classes["text-gray"]}  ${classes["font-weight-bold"]} mx-1`}>
                                            {`${program?.ProgramCurrency} ${program?.ApplicationFee?.FeeAmount}`}
                                            </span>
                                            <span className={`${classes["text-green"]}  ${classes["font-weight-bold"]} mx-1`}>
                                            {`${program?.ProgramCurrency} ${program?.ApplicationFeeAfterWaiver}`}
                                            </span>
                                            <span className={` font-weight-bold mx-1 ${classes["waiverPercentage"]}`}>
                                            {`${program?.WaiverPer}% Off`}
                                            </span>
                                        </div>
                                        </div>
                                    )}
                                    {program?.AverageProcessingDay && (
                                    <div className= {`d-flex bd-highlight  ${classes["subInst"]}`}>
                                        <div className= {`px-2 pb-2 pt-0 flex-fill bd-highlight ${classes["subInst1"]}`}>
                                        <p className={classes["InstSubTitle"]}>Processing Time</p>
                                        </div>
                                        <div className= {`px-2 pb-2 pt-0 flex-fill bd-highlight  ${classes["subInst2"]}`}>
                                        <p className={classes["Instsub"]}>{program?.AverageProcessingDay}</p>
                                        </div>
                                    </div>
                                    )}
                                    </div>
                                    <hr className="mt-0" />
                                    <div
                                    className={` ${classes["card-footer"]} text-center pr-2 ${classes["footer"]}`}
                                    >
                                    <button
                                        type="button"
                                        className={`${classes["btn-bor-blue"]} mx-1`}
                                        onClick={() => handleMoreProgram(program?.ProgramId)}
                                    >
                                        Read More
                                    </button>
                                    {program?.Intakes?.length > 0 && (
                                        <button
                                        type="button"
                                        className={`${classes["btn-agent-blue"]} mx-1`}
                                        onClick={()=>handleApplyClick(program)}
                                        >
                                        Apply Now
                                        </button>
                                    )}
                                    </div>
                                </div>
                                </div>
                            ))}
                            </div>

                            <div className="row no-gutters align-items-center mt10">
                                {item?.programs?.length > 0 && (
                                    <div className="col-12">
                                    <div className="d-flex justify-content-center">
                                        {item?.programs?.length > 4 && !item.noMoreResults && (
                                        <button
                                        type="button"
                                        className={`${classes["loadmore"]}`}
                                        id="loadMore"
                                        onClick={() => loadMore(item?.ProgramLevelId)}
                                        >
                                        {loadmore}
                                        <img className="px-2" src={downArrow} alt="" className="mx-2" width="17" />
                                        </button>
                                        )}
                                    </div>
                                    </div>
                                )}
                                </div>
                        </div>
                        ))}
                    </div>
                    )}


            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ProgramDetailPage;