import React, { useEffect, useRef, useState } from "react";
import classes from "./Documents.module.css";
import editIcon from "../../../../../Assets/Logo/edit-icon.svg";
import deleteICon from "../../../../../Assets/Logo/delete-icon.svg";
import addIcon from "../../../../../Assets/Logo/red add icon.svg";
import crossIcon1 from "../../../../../Assets/Logo/red-cross-icon.svg";
import crossIcon2 from "../../../../../Assets/Logo/red-2-cross-icon.svg";
import coinIcon from "../../../../../Assets/Logo/coin-icon.svg";
import locationIcon from "../../../../../Assets/Logo/location-icon.svg";
import calenderIcon from "../../../../../Assets/Logo/calender-icon.svg";
import passportIcon from "../../../../../Assets/Logo/passport-icon.svg";
import countryIcon from "../../../../../Assets/Logo/country-icon.svg";
import graduationCap from "../../../../../Assets/Logo/graduation-cap-icon.svg";
import programIcon from "../../../../../Assets/Logo/program-icon.svg";
import starIcon from "../../../../../Assets/Logo/star-icon.svg";
import governmentIdIcon from "../../../../../Assets/Logo/goverment-id-icon.svg";
// import lineIcon from "../../../../../Assets/Logo/line-hr.svg";
import visaIcon from "../../../../../Assets/Logo/Visa-icon.svg";
import eyeIcon from "../../../../../Assets/Logo/eye-icon.svg";
import documentIcon from "../../../../../Assets/Logo/document-icon.svg";
import upperArrowICon from "../../../../../Assets/Logo/ic-down-arrow 3.svg";
import dropArrowICon from "../../../../../Assets/Logo/ic-top-arrow.svg";
import UploadDocument from "../../../../../Components/GlobleShared/UploadDocument/UploadDocument";
import ProfileService from "../../../../../Services/ProfileService";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import Loader from "../../../../GlobleShared/Loader/Loader";
import SearchModal from "../../Search/SearchModal/SearchModal";
import crossIcon from "../../../../../Assets/Logo/cross-icon.svg";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import ApplicationService from "../../../../../Services/ApplicationServices";
import TestScores from "../../Applications/NewApplication/TestScores/TestScores";

function Documents() {
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [openDropdown, setOpenDropdown] = useState(null);
  const [userDetail] = useLocalStorage("userDetail");
  const studentID = userDetail.refId;
  const initial = useRef(null);
  const [selectedExam, setSelectedExam] = useState('');
  const userId = userDetail.user_id;
  const [isLoading, setIsLoading] = useState();
  const [passportModel, setPassportModel] = useState();
  const [passportData, setPassportData] = useState({});
  const [errors, setErrors] = useState({});
  const [visaerrors, setVisaErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [visaTypeList, setVisaTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [visaData, setVisaData] = useState([]);
  const [visaIdValue, setVisaIdValue] = useState([]);
  const [confirmationModalIsVisible, setConfirmationModalIsVisible] =
    useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [btnTextName, setBtnTextName] = useState("");
  const [docPathName, setDocPathName] = useState("");
  const [VisaStatusId, setVisaStatusId] = useState();
  const [educationData, setEducationData] = useState([]);
  const [schooleditModel, setSchoolEditModel] = useState(false);
  const [languageditModel, setLanguageditModel] = useState(false);
  const [educationDetails, setEducationDetails] = useState(null);
  const [testScoreData, setTestScoreData] = useState();
  const [studentData, setStudentData] = useState();
  const [programId, setProgramId] = useState(0);
  const [profileFlag, setProfileFlag] = useState(true);
  const [editTestScore, setEditTestScore] = useState(true);
  const [examDate, setExamDate] = useState();
  const [otherExamDate, setOtherExamDate] = useState();
  const today = new Date();
  const englishExamRange = [
    {
      Type: -1,
      Name: 'Not Applicable'
    },
    {
      Type: 1,
      Name: 'IELTS'
    },
    {
      Type: 2,
      Name: 'PTE'
    },
    {
      Type: 3,
      Name: 'TOEFL iBT'
    },
    {
      Type: 5,
      Name: 'MELAB'
    },
    {
      Type: 6,
      Name: 'CAEL'
    },
    {
      Type: 8,
      Name: 'Duolingo'
    }
  ];
  const OtherExams = [
    {
      OtherExamId: 0,
      OtherExamName: "None",
    },
    {
      OtherExamId: 1,
      OtherExamName: "ACT",
    },
    {
      OtherExamId: 2,
      OtherExamName: "GMAT",
    },
    {
      OtherExamId: 3,
      OtherExamName: "GRE",
    },
    {
      OtherExamId: 4,
      OtherExamName: "SAT",
    },

  ];
  const [initialFormVisa, setInitialFormVisa] = useState({});
  const [initialFormPass, setInitialFormPass] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [isButtonPassEnabled, setIsButtonPassEnabled] = useState(true);
  const [isEditvalue, setIsEditvalue] = useState(false);

  const [form, setForm] = useState({
    PassportNo: "",
    PassportExpiryDate: "",
    Citizenship: "",
    Citizenship: passportData.Citizenship || "",
    DocPath: passportData.DocPath || "",
  });
  const [isEditMode, setIsEditMode] = useState(false); // New state to track mode
  const [visaModel, setVisaModel] = useState(false);
  const [formVisa, setFormVisa] = useState({
    VisaNo: "",
    CountryId: "",
    VisaTypeId: "",
    VisaStatus: "",
    IssueDate: "",
    ValidUpto: "",
    Comment: "",
    DocumentPath: "",
  });

  const getSchoolDetailList = () => {
    let data = {
      ParentType: 6,
      ParentId: studentID,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setEducationData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchStatusTypes();
  }, [formVisa.VisaTypeId]);


  const openEditPassModal=()=>{
    getPassportDetail(studentID);
    setInitialFormPass(form);
    setIsEditvalue(true);
    setIsButtonPassEnabled(false);
    setErrors('');
    
  }

  const openAddPassModal = ()=>{
    setIsEditvalue(false);
    setInitialFormPass({});
    setForm({});
    setErrors({});
    
  }

  const handleChange = (e) => {
  
    const { name, value } = e.target;

    const updatedForm = {
      ...form,
      [name]: value,
    };
  
    setForm(updatedForm);
  
    if(isEditvalue){
    const isChanged = Object.keys(initialFormPass).some(
      (key) => initialFormPass[key] !== updatedForm[key]
    );
    setIsButtonPassEnabled(isChanged);
  }
  

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };


  const validateFormPassport = () => {
    let formErrors = {};

    if (!form.PassportNo) {
      formErrors.PassportNo = "Passport number is required";
    }
    if (!form.PassportExpiryDate) {
      formErrors.PassportExpiryDate = "Expiry date is required";
    }
    if (!form.Citizenship || form.Citizenship === "") {
      formErrors.Citizenship = "Citizenship is required";
    }
    if (!form.DocPath || form.DocPath === "") {
      formErrors.DocPath = "Document upload is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const validateFormVisa = () => {
    let formErrors = {};

    // Check CountryId
    if (!formVisa.CountryId || formVisa.CountryId === "") {
      formErrors.CountryId = "Country is required";
    }

    // Check VisaTypeId
    if (!formVisa.VisaTypeId || formVisa.VisaTypeId === "") {
      formErrors.VisaTypeId = "Type is required";
    }

    // Check VisaStatus
    if (formVisa.VisaStatus === null || formVisa.VisaStatus === undefined || formVisa.VisaStatus === "") {
      formErrors.VisaStatus = "Status is required";
    }
    

    // Validate IssueDate and ValidUpto if VisaStatus is 'Approved'
    if (formVisa.VisaStatus === "2" || formVisa.VisaStatus === "2") {
      if (!formVisa.IssueDate || formVisa.IssueDate.trim() === "") {
        formErrors.IssueDate = "Issue Date is required";
      }

      if (!formVisa.ValidUpto || formVisa.ValidUpto.trim() === "") {
        formErrors.ValidUpto = "Valid Upto is required";
      }
    }
    if (!formVisa.DocumentPath || formVisa.DocumentPath === "") {
      formErrors.DocumentPath = "Document upload is required";
    }

    setVisaErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateFormPassport()) {
      return;
    }
    if (form.DocPath !== docPathName) {
      getStudentDocument()
    }
    setIsLoading(true);
    const payload = {
      ...form,
      StudentId: studentID
    }
    ProfileService.updatePassportDetail(payload).then((res) => {
      toast.success(res.message);
      getPassportDetail(studentID);
      setPassportModel(false)
      setIsLoading(false);
    }).catch((err) => {
      console.log('err: ', err);
      setIsLoading(false);
    });
  };
  const getPassportDetail = (id) => {
    setIsLoading(true);
    ProfileService.getPassportDetail(id)
      .then((res) => {
        setPassportData(res);
        setDocPathName(res?.DocPath);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
      });
  };
  const handleExamChange = (event) => {
    setSelectedExam(event.target.value);
  };
  const countryData = () => {
    setIsLoading(true);
    ProfileService.countryList()
      .then((res) => {
        setCountryList(res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
      });
  };



  const handleVisaChange = (e) => {
      const { name, value } = e.target;
 
      const updatedFormVisa = {
        ...formVisa,
        [name]: value,
        ...(name === "VisaStatus" && { IssueDate: "", ValidUpto: "" }), 
      };
    
      setFormVisa(updatedFormVisa);
  
      if (isEditMode) {
      const isChanged = Object.keys(initialFormVisa).some(
        (key) => initialFormVisa[key] !== updatedFormVisa[key]
      );
      setIsButtonEnabled(isChanged);
      }
      
    setVisaErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "CountryId") {
      fetchVisaTypes(value);
    }
    if (name === "VisaTypeId") {
      fetchStatusTypes();
    }
  };

  const fetchVisaTypes = (countryId) => {
    setIsLoading(true);
    ProfileService.VisaType(countryId)
      .then((res) => {
        setVisaTypeList(res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
      });
  };
  const fetchStatusTypes = () => {
    setIsLoading(true);
    ProfileService.StatusType()
      .then((res) => {
        setStatusList(res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
      });
  };
  const getStudentVisa = (id) => {
    setIsLoading(true);
    ProfileService.getStudentVisa(id)
      .then((res) => {
        setVisaData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setIsLoading(false);
      });
  };

  const getStudentDocument = () => {
    setIsLoading(true);
    const payload = {
      DocPath: docPathName,
      DocumentId: passportData.DocumentId ? passportData.DocumentId : 0,
      DocumentName: "",
      DocumentTypeId: 1,
      DueDate: "",
      ExpiryDate: "",
      ParentId: studentID,
      ParentType: 6,
    };
    ProfileService.addStudentDocument(payload)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    countryData();
    getStudentVisa(studentID);
    getPassportDetail(studentID);
    getSchoolDetailList();
  }, [studentID]);

  useEffect(() => {
    if (passportData) {
      setForm(() => ({
        PassportNo: passportData.PassportNo || "",
        PassportExpiryDate: passportData.PassportExpiryDate || "",
        Citizenship: passportData.Citizenship || "",
        DocPath: passportData.DocPath ? passportData.DocPath : ""
      }));
    }
  }, [passportData]);
  const toggleDropdown = (id) => {
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === id ? null : id
    );
  };

  const deleteVisa = (VisaId, userId) => {
    setIsLoading(true);
    ProfileService.deleteStudentVisa(VisaId, userId)
      .then((res) => {
        toast.success("Visa Deleted Successfully ");
        setConfirmationModalIsVisible(false);
        setIsLoading(false);
        getStudentVisa(studentID);
      })
      .catch((err) => {
        console.log("err: ", err);
        setIsLoading(false);
      });
  };
  const getConfirmation = () => {
    deleteVisa(visaIdValue, userId);
  };
  const hideConfirmationModalHandler = () => {
    setConfirmationModalIsVisible(false);
  };
  const handleRemoveVisa = (visaId) => {
    console.log("visaId: ", visaId);
    setConfirmationModalIsVisible(true);
    setConfirmationText("Are you sure, you want to remove the selected visa?");
    setBtnTextName("Remove");
    setVisaIdValue(visaId);
  };
// debugger;


const uploadDocFile = (file) => {
  setDocPathName(file);
  if (file) {

    const updatedForm = {
      ...form,
      DocPath: file,
    };

    setForm(updatedForm);

    if(isEditvalue){
    const isChanged = Object.keys(initialFormPass).some(
      (key) => initialFormPass[key] !== updatedForm[key]
    );
    setIsButtonPassEnabled(isChanged);
  }

    setErrors((prevErrors) => {
      const { DocPath, ...rest } = prevErrors;
      return rest;
    });

  } else {
    setErrors((prevErrors) => ({
      ...prevErrors,
      DocPath: "Document upload is required",
    }));
  }
};
  useEffect(() => {
    if (form.DocPath) {
      getStudentDocument();
    }
  }, [form.DocPath]);

  const openVisaModal = (visa = null) => {
    let matchedStatus;

    if (visa) {
      // Update mode
      setIsEditMode(true);
      setVisaStatusId(visa.VisaStatusId);

      matchedStatus = statusList.find(
        (status) => status.StatusName === visa.VisaStatus
      );

      setFormVisa({
        StudentVisaId: visa.StudentVisaId || "",
        CountryId: visa.CountryId || "",
        VisaTypeId: visa.VisaTypeId || "",
        VisaStatus: matchedStatus ? matchedStatus.StatusId : "",
        IssueDate: visa.IssueDate ? visa.IssueDate : "",
        ValidUpto: visa.ValidUpto ? visa.ValidUpto : "",
        Comment: visa.Comment || "",
        DocumentPath: visa.DocumentPath || "",
      });

      setInitialFormVisa(visa);
      setIsButtonEnabled(false);

      // Fetch Visa Types and Statuses if not already available
      if (visaTypeList.length === 0) {
        fetchVisaTypes(visa.CountryId); // Fetch only if empty
      }

      if (statusList.length === 0) {
        fetchStatusTypes(); // Fetch only if empty
      }
    } else {
      // New visa entry
      setIsEditMode(false);
      setFormVisa({
        StudentVisaId: 0,
        CountryId: "",
        VisaTypeId: "",
        VisaStatus: "",
        IssueDate: "",
        ValidUpto: "",
        Comment: "",
        DocumentPath: "",
      });

      setInitialFormVisa({})
      setIsButtonEnabled(true);

      // Fetch Visa Types and Statuses if not already available
      if (visaTypeList.length === 0) {
        fetchVisaTypes(); // You might need to set a default country ID or leave it empty
      }

      if (statusList.length === 0) {
        fetchStatusTypes();
      }
    }

    // Open the modal
    setVisaModel(true);
  };

  const handleSchoolEditClick = (school) => {
    setEducationDetails(school);
    setSchoolEditModel(true);
  };

  useEffect(() => {
    if (studentID) {
      let updatedStudentData = {};
      let examType = null;
      let otherExamType = null;
      
      ApplicationService.getStudentData(studentID, programId)
        .then((res) => {
          updatedStudentData = res;
          setExamDate(new Date(updatedStudentData?.testScore?.EnglishExamDate));
          setOtherExamDate(new Date(updatedStudentData?.testScore?.GMATExamDate));
          examType = updatedStudentData?.testScore?.EnglishExamType;
          otherExamType = updatedStudentData?.testScore?.GMATExam;
          englishExamRange.filter((item) => {
            if(item.Type === examType){
              updatedStudentData.testScore["EnglishExamName"] = item.Name;
            }
          })
          OtherExams.filter((item) => {
            if (otherExamType && item.OtherExamId === otherExamType) {
              updatedStudentData.testScore["GMATExamName"] = item.OtherExamName;
            }
          })
          setStudentData(updatedStudentData);
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  }, [studentID, programId]);

  const handleLanguageEditClick = () => {
    // setEducationDetails(school);
    setIsLoading(true);
    ApplicationService.getStudentData(studentID, programId)
      .then((res) => {
        setIsLoading(false);
        setStudentData(res);
        setLanguageditModel(true);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  // const handleLanguageClose = () => {
  //   setLanguageditModel(false);
  // };
  const handleLanguageClose = () => {
    setLanguageditModel(false);
    let updatedStudentData = {};
    let examType = null;
    let otherExamType = null;
    // Refresh student data after closing
    ApplicationService.getStudentData(studentID, programId)
      .then((res) => {
        updatedStudentData = res;
        setExamDate(new Date(updatedStudentData?.testScore?.EnglishExamDate));
        setOtherExamDate(new Date(updatedStudentData?.testScore?.GMATExamDate));
        examType = updatedStudentData?.testScore?.EnglishExamType;
        otherExamType = updatedStudentData?.testScore?.GMATExam;
        englishExamRange.filter((item) => {
          if (item.Type === examType) {
            updatedStudentData.testScore["EnglishExamName"] = item.Name;
          }
        })
        OtherExams.filter((item) => {
          if (otherExamType && item.OtherExamId === otherExamType) {
            updatedStudentData.testScore["GMATExamName"] = item.OtherExamName;
          }
        })
        setStudentData(updatedStudentData);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleTestScoreUpdate = (updatedData) => {
    setStudentData(prevData => ({
      ...prevData,
      testScore: updatedData
    }));
  };

  const handleFileUploadVisa = (file) => {
    if (file) {
      const updatedFormVisa = {
        ...formVisa,
        DocumentPath: file,
      };
  
      setFormVisa(updatedFormVisa);
  
      if(isEditMode){
      const isChanged = Object.keys(initialFormVisa).some(
        (key) => initialFormVisa[key] !== updatedFormVisa[key]
      );
      setIsButtonEnabled(isChanged);
    }

      setVisaErrors((prevErrors) => {
        const { DocumentPath, ...rest } = prevErrors;
        return rest;
      });
    } else {
      setVisaErrors((prevErrors) => ({
        ...prevErrors,
        DocumentPath: "Document upload is required",
      }));
    }
  };


  const handleSubmitVisa = () => {
    if (!validateFormVisa()) {
      return;
    }
    setIsLoading(true);
    const payload = {
      ...formVisa,
      StudentId: studentID,
      UserId: userId,
      VisaTypeId: parseInt(formVisa.VisaTypeId, 10),
      VisaStatus: parseInt(formVisa.VisaStatus, 10),
    };
  
    if (isEditMode) {
      ProfileService.updateStudentVisa(payload)
        .then((res) => {
          toast.success(res.message);
          getStudentVisa(studentID);
          setIsLoading(false);
          setVisaModel(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error updating visa:", err);
        });
    } else {
      ProfileService.addStudentVisa(payload)
        .then((res) => {
          toast.success(res.message);
          getStudentVisa(studentID);
          setVisaModel(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error adding visa:", err);
        });
    }
  };

  const handleFileUpload = (file) => {
    setFormVisa((prevForm) => ({
      ...prevForm,
      DocumentPath: file,
    }));
  };

  const handleFileUploadEducation = (file) => {
    setEducationDetails((prevForm) => ({
      ...prevForm,
      ExamDocUrl: file,
    }));
  };

  const refreshSchoolList = () => {
    getSchoolDetailList();
  };

  const handleUpdateEducation = () => {
    const data = {
      SchoolId: educationDetails.SchoolId || 0,
      ParentId: educationDetails.ParentId || studentID,
      ParentType: educationDetails.ParentType || 6,
      LevelOfEducation: educationDetails.LevelOfEducation || 0,
      LevelOfEducationName: educationDetails.LevelOfEducationName || "",
      CountryOfInstitution: educationDetails.CountryOfInstitution || 0,
      CountryOfInstitutionName: educationDetails.CountryOfInstitutionName || "",
      NameOfInstitution: educationDetails.NameOfInstitution || "",
      Language: educationDetails.Language || "",
      AttendendFrom: educationDetails.AttendendFrom || "",
      AttendendTo: educationDetails.AttendendTo || "",
      Degree: educationDetails.Degree || "",
      DegreeAwardedOn: educationDetails.DegreeAwardedOn || "",
      Addres: educationDetails.Addres || "",
      City: educationDetails.City || "",
      Province: educationDetails.Province || "",
      Pincode: educationDetails.Pincode || "",
      Marks: educationDetails.Marks.length > 0 ? educationDetails.Marks : [],
      StreamId: educationDetails.StreamId || 0,
      institutionId: educationDetails.institutionId || "",
      ExamDocUrl: educationDetails.ExamDocUrl || "",
    };

    setIsLoading(true);
    ApplicationService.updateSchoolDetail(data)
      .then((res) => {
        setIsLoading(false);
        toast.success("Education Document Uploaded successfully!");
        refreshSchoolList();
        setEducationDetails("");
        setSchoolEditModel(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const closeVisaModal = () => {
    // setFormVisa({
    //   StudentVisaId: 0,
    //   CountryId: '',
    //   VisaTypeId: '',
    //   VisaStatus: '',
    //   IssueDate: '',
    //   ValidUpto: '',
    //   Comment: '',
    //   DocumentPath: ''
    // });

    // Clear all validation errors when closing the modal
    setVisaErrors({});

    // Optionally, close the modal here
    setVisaModel(false);
  };

  const getTestScore = (data) => {
    setTestScoreData(data);
  };

  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
    }
  }, []);

  return (
    <div>
      {confirmationModalIsVisible && (
        <ConfirmationModal
          onClose={hideConfirmationModalHandler}
          confirmationText={confirmationText}
          btnText={btnTextName}
          onShow={confirmationModalIsVisible}
          getConfirmation={getConfirmation}
        ></ConfirmationModal>
      )}
      {isLoading && <Loader />}
      {passportModel && (
        <SearchModal onShow={passportModel} size="lg">
          <div className={classes["modal-container"]}>
            <div className="d-flex justify-content-between">
              <div className={`${classes["heading-text-modal"]} m-2`}>
                Passport Details
              </div>
              <div
                className={classes["cross-icon"]}
                onClick={() => setPassportModel(false)}
              >
                <img src={crossIcon1} style={{width: '20px', marginRight:'15px', marginTop:'15px'}}alt="Close" />
              </div>
            </div>
            <div className={classes["modal-body"]}>
              <div className="row">
                <div className="form-group col-md-4 mt-4">
                  <label className={classes["forLabel"]}>Passport No</label>
                  <input
                    type="text"
                    className="form-control"
                    name="PassportNo"
                    value={form.PassportNo}
                    onChange={handleChange}
                    required
                  />
                  {errors.PassportNo && (
                    <div className={classes["errorText"]}>
                      {errors.PassportNo}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-4 mt-4">
                  <label className={classes["forLabel"]}>Expiry Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="PassportExpiryDate"
                    value={form.PassportExpiryDate}
                    onChange={handleChange}
                    required
                  />
                  {errors.PassportExpiryDate && (
                    <div className={classes["errorText"]}>
                      {errors.PassportExpiryDate}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-4 mt-4">
                  <label className={classes["forLabel"]}>Country</label>
                  <select
                    className="form-select"
                    name="Citizenship"
                    value={form.Citizenship}
                    onChange={handleChange}
                  >
                    <option value="" className={classes["option-default"]}>---select---</option>
                    {countryList.map((country) => (
                      <option key={country.CountryId} value={country.CountryId}>
                        {country.CountryName}
                      </option>
                    ))}
                  </select>
                  {errors.Citizenship && (
                    <div className={classes["errorText"]}>
                      {errors.Citizenship}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group col-md-12 mt-4">
                <label className={classes["forLabel"]}>Media Upload</label>
                <div>
                  <label className={classes["titleLabel"]}>
                    Add your Document here and you can upload up to 5 MB Max
                  </label>
                </div>
                <UploadDocument
                  btnText="Upload File"
                  uploadFile={uploadDocFile}
                  filePathUrl={form.DocPath}
                />
                {errors.DocPath && (
                  <div className={classes["errorText"]}>{errors.DocPath}</div>
                )}
              </div>
            </div>

            <div className={classes["modal-footer"]}>
              <button  disabled={  !isButtonPassEnabled || !form.PassportNo ||
                 !form.PassportExpiryDate || !form.Citizenship || (!form.DocPath ||
                  form.DocPath === "") || Object.keys(errors).some((key) => errors[key])} className="primary-button m-4" onClick={handleSubmit}   >
                Save Details
              </button>
            </div>
          </div>
        </SearchModal>
      )}

      {visaModel && (
        <SearchModal onShow={visaModel} size={"lg"}>
          <div className={classes["modal-container"]}>
            <div className="d-flex justify-content-between">
              <div className={`${classes["heading-text-modal"]} m-2`}>
                {isEditMode ? "Update Visa Details" : "Add Visa Details"}
              </div>
              <div
                className={classes["cross-icon"]}
                onClick={() => closeVisaModal()}
              >
    <img src={crossIcon1} style={{ marginRight: '15px', marginTop: '15px' }} alt="Close" />
              </div>
            </div>
            <div className={classes["modal-body"]}>
              <div className="row">
                <div className="form-group col-md-4 mt-4">
                  <label className={classes["forLabel"]}>Country</label>
                  <select
                    className="form-select"
                    name="CountryId"
                    value={formVisa.CountryId}
                    onChange={handleVisaChange}
                  >
                    <option value="" className={classes["option-default"]}>---select---</option>
                    {countryList.map((country) => (
                      <option key={country.CountryId} value={country.CountryId}>
                        {country.CountryName}
                      </option>
                    ))}
                  </select>
                  {visaerrors.CountryId && (
                    <div className={classes["errorText"]}>
                      {visaerrors.CountryId}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-4 mt-4">
                  <label className={classes["forLabel"]}>Visa Type</label>
                  <select
                    className="form-select"
                    name="VisaTypeId"
                    value={formVisa.VisaTypeId}
                    onChange={handleVisaChange}
                  >
                    <option value="" className={classes["option-default"]}>---select---</option>
                    {visaTypeList.map((visaType) => (
                      <option
                        key={visaType.VisaTypeId}
                        value={visaType.VisaTypeId}
                      >
                        {visaType.VisaTypeName}
                      </option>
                    ))}
                  </select>
                  {visaerrors.VisaTypeId && (
                    <div className={classes["errorText"]}>
                      {visaerrors.VisaTypeId}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-4 mt-4">
                  <label className={classes["forLabel"]}>Status</label>
                  <select
                    className="form-select"
                    name="VisaStatus"
                    value={formVisa.VisaStatus}
                    onChange={handleVisaChange}
                  >
                    <option value="" className={classes["option-default"]}>---select---</option>
                    {statusList.map((status) => (
                      <option key={status.StatusId} value={status.StatusId}>
                        {status.StatusName}
                      </option>
                    ))}
                  </select>
                  {visaerrors.VisaStatus && (
                    <div className={classes["errorText"]}>
                      {visaerrors.VisaStatus}
                    </div>
                  )}
                </div>

                {(formVisa.VisaStatus === 2 || formVisa.VisaStatus === "2") && (
                  <>
                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>Issue Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="IssueDate"
                        value={formVisa.IssueDate}
                        onChange={handleVisaChange}
                        required
                      />
                      {visaerrors.IssueDate && (
                        <div className={classes["errorText"]}>
                          {visaerrors.IssueDate}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-4 mt-4">
                      <label className={classes["forLabel"]}>Valid Upto</label>
                      <input
                        type="date"
                        className="form-control"
                        name="ValidUpto"
                        value={formVisa.ValidUpto}
                        onChange={handleVisaChange}
                        min={
                          formVisa.IssueDate ||
                          new Date().toISOString().split("T")[0]
                        }
                        required
                      />
                      {visaerrors.ValidUpto && (
                        <div className={classes["errorText"]}>
                          {visaerrors.ValidUpto}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="form-group col-md-12 mt-4">
                  <label className={classes["forLabel"]}>Comment</label>
                  <textarea
                    className="form-control"
                    name="Comment"
                    value={formVisa.Comment}
                    onChange={handleVisaChange}
                  />
                  {visaerrors.Comment && (
                    <div className={classes["errorText"]}>
                      {visaerrors.Comment}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-12 mt-4">
                <label className={classes["forLabel"]}>Media Upload</label>
                <div>
                  <label className={classes["titleLabel"]}>
                    Add your Document here and you can upload up to 5 MB Max
                  </label>
               
                  <UploadDocument
                    btnText="Upload File"
                    uploadFile={handleFileUploadVisa}
                    filePathUrl={formVisa.DocumentPath}
                  />
                  {visaerrors.DocumentPath && (
                  <div className={classes["errorText"]}>{visaerrors.DocumentPath}</div>
                )}
                 <div className={classes["modal-footer"]}>
                 <button 
                    disabled={
                      !isButtonEnabled ||
                      !formVisa.VisaTypeId || 
                      !formVisa.CountryId || 
                      !formVisa.DocumentPath || 
                      formVisa.DocumentPath === "" || 
                      (formVisa.VisaStatus === 2 || formVisa.VisaStatus === "2" 
                        ? !formVisa.ValidUpto || !formVisa.IssueDate 
                        : formVisa.VisaStatus === "" || formVisa.VisaStatus === null || formVisa.VisaStatus === undefined)
                        || Object.keys(visaerrors).some((key) => visaerrors[key])
                    }
                    className="primary-button m-4" 
                    onClick={handleSubmitVisa}
                  >
                    Save Details
                  </button>

                  </div>
                 </div>
                </div>
              </div>
            </div>

           
          </div>
        </SearchModal>
      )}

      {languageditModel && (
  <SearchModal onShow={languageditModel} size="xl" style={{ minHeight: "120px" }}>
    <div className={classes["modal-container"]}>
      <div className="d-flex justify-content-between">
        <div className={`${classes["heading-text-modal"]} m-2`}>
          Language and Other Exam Details
        </div>
        <div className={classes["cross-icon"]} onClick={handleLanguageClose}>
          <img src={crossIcon1}  style={{ marginRight: '15px', marginTop: '15px' }}alt="Close" />
        </div>
      </div>
      <div className={classes["modal-body"]}>
        {studentData && (
          <TestScores
            getTestScore={getTestScore}
            testScoreData={studentData?.testScore}
            profileFlag={profileFlag}
            handleLanguageClose={handleLanguageClose}
            editTestScore={editTestScore}
            onUpdate={handleTestScoreUpdate}
          />
        )}
      </div>
    </div>
  </SearchModal>
)}

      {schooleditModel && (
        <SearchModal
          onShow={schooleditModel}
          size="md"
          style={{ minHeight: "80px" }}
        >
          <div className={classes["passport-modal"]}>
            <div className={classes["passport-header"]}>
              <div className={`${classes["passport-title"]} ps-2`}>
                Upload Education Document
              </div>
              <div
                className={classes["passport-crose-icon"]}
                onClick={() => setSchoolEditModel(false)}
              >
                <img
                  src={crossIcon1}
                  style={{height: '20px', cursor: "pointer"}}
                  alt="Close"
                />
              </div>
            </div>
              <div title="Add your Document here and you can upload up to 5 MB Max">
                  <label className={classes["titleLabel"]}>
                    Add your Document here and you can upload up to 5 MB Max
                  </label>
                </div>
            <div className={classes["passport-body"]}>
              <div className="d-flex align-items-center">
                <UploadDocument
                  btnText="Upload Document"
                  uploadFile={(file) => handleFileUploadEducation(file)}
                  filePathUrl={educationDetails.ExamDocUrl}
                />
              </div>
            </div>
            <div className="mt-3 text-center">
              <button
                className="primary-button"
                onClick={handleUpdateEducation}
              >
                Save Details
              </button>
            </div>
          </div>
        </SearchModal>
      )}

      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={passportIcon}
                    alt=""
                  ></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>
                Passport:{" "}
                {passportData.PassportNo ? passportData.PassportNo : "N/A"}
              </div>
            </div>
            <div>
              {/* <span className={classes["coin-count"]}>5</span>
  <span>
    <img
      className={classes["coin-image-size"]}
      src={coinIcon}
      alt=""></img>
  </span> */}
              {passportData && passportData.PassportNo ? (
                <img
                  className={classes["edit-icon"]}
                  src={editIcon}
                  alt="Edit Passport"
                  onClick={() => {setPassportModel(true); openEditPassModal()}}
                />
              ) : (
                <img
                  className={classes["add-icon"]}
                  src={addIcon}
                  alt="Add Passport"
                   onClick={() => {setPassportModel(true); openAddPassModal()}}
                />
              )}
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={countryIcon}
                    alt=""
                  ></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>
                {passportData.Citizenship
                  ? countryList.find(
                      (country) =>
                        country.CountryId === passportData.Citizenship
                    )?.CountryName
                  : "N/A"}
              </div>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={calenderIcon}
                    alt=""
                  ></img>
                </span>
              </div>
              <div className={`${classes["grey-text"]}`}>
                {passportData.PassportExpiryDate
                  ? new Date(
                      passportData.PassportExpiryDate
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                  : "NA"}
              </div>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={documentIcon}
                    alt=""
                  ></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>Document</div>
              {passportData.DocPath ? (
                <div className="">
                  <span>
                    <img
                      className={classes["educationIcon-image-size"]}
                      src={eyeIcon}
                      alt=""
                      onClick={() =>
                        window.open(
                          `${blobUrl}${passportData.DocPath}`,
                          "_blank"
                        )
                      }
                    ></img>
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["heading-align"]}>
            <div>
              <p className={classes["heading-text"]}>Visa</p>
            </div>
            <div>
              {/* <span className={classes["coin-count"]}>5</span>
              <span>
                <img
                  className={classes["coin-image-size"]}
                  src={coinIcon}
                  alt=""></img>
              </span> */}
              <img
                className={classes["image-size"]}
                src={addIcon}
                onClick={() => openVisaModal()}
                alt=""
              ></img>
            </div>
          </div>
          <div>
            {visaData.map((visa, index) => (
              <div key={visa.StudentVisaId}>
                <div className={classes["icon-alignment1"]}>
                  <div className="d-flex">
                    <div>
                      <span>
                        <img
                          className={classes["educationIcon-image-size"]}
                          src={visaIcon}
                          alt=""
                        ></img>
                      </span>
                    </div>
                    <div className={` ${classes["grey-text"]}`}>
                      {visa.VisaTypeName}
                    </div>
                    <div className="ms-4">
                      <span>
                        <img
                          className={classes["edit-icon"]}
                          src={
                            openDropdown == visa.StudentVisaId
                              ? upperArrowICon
                              : dropArrowICon
                          }
                          alt=""
                          onClick={() => toggleDropdown(visa.StudentVisaId)}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <span>
                      <img
                        className={classes["edit-icon"]}
                        src={deleteICon}
                        onClick={() => handleRemoveVisa(visa.StudentVisaId)}
                        alt=""
                      ></img>
                    </span>
                    <span>
                      {" "}
                      <img
                        className={classes["edit-icon"]}
                        src={editIcon}
                        alt=""
                        onClick={() => openVisaModal(visa)}
                      ></img>
                    </span>
                  </div>
                </div>
                {openDropdown === visa.StudentVisaId && (
                  <div className="dropDownContainer">
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex">
                        <div className="">
                          <span>
                            <img
                              className={classes["educationIcon-image-size"]}
                              src={locationIcon}
                              alt=""
                            ></img>
                          </span>
                        </div>
                        <div className={` ${classes["grey-text"]}`}>
                          {visa.CountryName}
                        </div>
                      </div>
                    </div>
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex">
                        <div>
                          <span>
                            <img
                              className={classes["educationIcon-image-size"]}
                              src={documentIcon}
                              alt=""
                            ></img>
                          </span>
                        </div>
                        <div className={` ${classes["grey-text"]}`}>
                          Document
                        </div>
                        {visa.DocumentPath ? (
                          <div className="">
                            <span>
                              <img
                                className={classes["educationIcon-image-size"]}
                                src={eyeIcon}
                                alt=""
                                onClick={() =>
                                  window.open(
                                    `${blobUrl}${visa.DocumentPath}`,
                                    "_blank"
                                  )
                                }
                              ></img>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* {index < educationData.length - 1 && (
                  <img
                    className={`${classes.imageLineHeight}`}
                    src={lineIcon}
                  ></img>
                )} */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["heading-align"]}>
            <div>
              <p className={classes["heading-text"]}>Education</p>
            </div>
            {/* <div className="d-flex align-items-center">
              <span className={classes["coin-count"]}>5</span>
              <span><img className={classes["coin-image-size"]} src={coinIcon} alt="" /></span>
            </div> */}
            {/* <img onClick={addEducationModalOpen} className={classes["image-size"]} src={addIcon} alt="" /> */}
          </div>
          <div>
            {educationData.map((school, index) => (
              <div key={school.id}>
                <div className={classes["icon-alignment1"]}>
                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                  <div className="d-flex">
                    <div>
                      <span>
                        <img
                          className={classes["educationIcon-image-size"]}
                          src={graduationCap}
                          alt=""
                        />{" "}
                      </span>
                    </div>
                    <div className={`${classes["grey-text"]}`}>
                      {school.NameOfInstitution}
                    </div>

                    <div className="ms-4">
                      <span>
                        <img
                          className={classes["edit-icon"]}
                          src={
                            openDropdown === school.SchoolId
                              ? upperArrowICon
                              : dropArrowICon
                          }
                          alt=""
                          onClick={() => toggleDropdown(school.SchoolId)}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                {openDropdown === school.SchoolId && (
                  <div className={classes["dropDownContainer"]}>
                    {/* Level of Education */}
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img
                          className={classes["educationIcon-image-size"]}
                          src={programIcon}
                          alt=""
                        />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.LevelOfEducationName
                            ? school.LevelOfEducationName
                            : "NA"}
                        </div>
                      </div>
                    </div>

                    {/* Marks/Grade */}
                    {/* <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={starIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.Marks.length > 0 ? school.Marks[0].GradeName : "NA"}
                        </div>
                      </div>
                    </div> */}

                    {/* Marks/Obtained */}
                    {/* <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={starIcon} alt="" />
                        <div className={`${classes["grey-text"]}`}>
                          {school.Marks.length > 0 ? school.Marks[0].ObtainMarks : "NA"}
                        </div>
                      </div>
                    </div> */}

                    {/* Attended From */}
                    {/* <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={calenderIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.AttendendFrom ? new Date(school.AttendendFrom).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}
                        </div>
                      </div>
                    </div> */}

                    {/* Attended To */}
                    {/* <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={calenderIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.AttendendTo ? new Date(school.AttendendTo).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : "NA"}
                        </div>
                      </div>
                    </div> */}

                    {/* Country of Institution */}
                    {/* <div className={classes["icon-alignment"]}>
                      <div className="d-flex align-items-center">
                        <img className={classes["educationIcon-image-size"]} src={locationIcon} alt="" />
                        <div className={`  ${classes["grey-text"]}`}>
                          {school.CountryOfInstitutionName ? school.CountryOfInstitutionName : "NA"}
                        </div>
                      </div>
                    </div> */}

                    {/* Document row */}
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          className={classes["educationIcon-image-size"]}
                          src={documentIcon}
                          alt=""
                        />
                        <div className={`  ${classes["grey-text"]}`}>
                          Document
                        </div>
                        {school.ExamDocUrl && (
                          <div>
                            <span>
                              <img
                                className={classes["educationIcon-image-size"]}
                                src={eyeIcon}
                                alt=""
                                onClick={() =>
                                  window.open(
                                    `${blobUrl}${school.ExamDocUrl}`,
                                    "_blank"
                                  )
                                }
                              />
                            </span>
                          </div>
                        )}
                      </div>

                      {/* Edit icon only for documents */}
                      <div>
                        <img
                          className={classes["edit-icon"]}
                          src={editIcon}
                          alt=""
                          onClick={() => handleSchoolEditClick(school)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* {index < educationData.length - 1 && (
                  <img
                    className={`${classes.imageLineHeight}`}
                    src={lineIcon}
                  />
                )} */}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* textscore */}
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["testscore-card"]}>
            <div className={classes["testscore-head"]}>
              <div className={classes["heading-text"]}> Language and Other Exam Details</div>
              <div>
                {/* {studentData && studentData.testScore && (
                  studentData.testScore.EnglishExamDocPath ||
                  studentData.testScore.OtherExamDocPath
                ) ? ( */}
                {studentData && studentData.testScore ? (
                  <img
                    className={classes["edit-icon"]}
                    src={editIcon}
                    onClick={() => handleLanguageEditClick()}
                    alt="Edit Language and Other Exam Details"
                  />
                ) : (
                  <img
                    className={classes["add-icon"]}
                    src={addIcon}
                    onClick={() => handleLanguageEditClick()}
                    alt="Add Language and Other Exam Details"
                  />

                )}
              </div>
            </div>
            {studentData?.testScore?.EnglishExamType && (
              <div className={classes["testscore-body"]}>
                {studentData?.testScore?.EnglishExamType === -1 && (
                  <div>
                  <div className={classes["body-section-1"]}>
                    <div className={classes["exam-type"]}>{studentData?.testScore?.EnglishExamName}</div>
                    <div>{studentData?.testScore?.EnglishExamRemark}</div>
                  </div>
                </div>
                )}
                {studentData?.testScore?.EnglishExamType !== -1 && (
                  <div>
                  <div className={classes["body-section-1"]}>
                    <div className={classes["exam-type"]}>{studentData?.testScore?.EnglishExamName}</div>
                    <div className={(examDate > today) ? classes["exam-date"] : classes[""]}>{studentData?.testScore?.EnglishExamDate}</div>
                  </div>
                  {examDate <= today && (
                    <div className={classes["body-section-2"]}>
                    {studentData?.testScore?.EnglishExamDate !== 8 && (
                      <div className={classes["exam-scores"]}>
                        <div>Listening: <span className={classes["score"]}>{studentData?.testScore?.EnglishScoreL}</span></div>
                        <div>{studentData?.testScore?.EnglishExamType === 5 ? 'GCVR' : 'Reading'}: <span className={classes["score"]}>{studentData?.testScore?.EnglishScoreR}</span></div>
                        <div>Writing: <span className={classes["score"]}>{studentData?.testScore?.EnglishScoreW}</span></div>
                        <div>Speaking: <span className={classes["score"]}>{studentData?.testScore?.EnglishScoreS}</span></div>
                      </div>
                    )}
                    <div className={classes["overall-score"]}>
                      <div>{studentData?.testScore?.EnglishExamType === 5 ? 'Final MELAB' : 'Overall'}:</div>
                      <div className={classes["score"]}>{studentData?.testScore?.EnglishScoreOverall}</div>
                    </div>
                  </div>
                  )}
                  {studentData.testScore.EnglishExamDocPath && studentData.testScore.EnglishExamDocPath !== "" && (
                    <div className={classes["body-section-3"]}>
                      <div>Document:</div>
                      <div className={classes["document-file"]}>{studentData?.testScore?.EnglishExamDocPath}</div>
                    </div>
                  )}
                </div>
                )}
              </div>
            )}
            {studentData?.testScore?.GMATExam > 0 && (
              <div className={classes["testscore-divider"]}></div>
            )}
            {studentData?.testScore?.GMATExam > 0 && (
              <div className={classes["testscore-body"]}>
                <div>
                  <div className={classes["body-section-1"]}>
                    <div className={classes["exam-type"]}>{studentData?.testScore?.GMATExamName}</div>
                    <div className={(otherExamDate > today) ? classes["exam-date"] : classes[""]}>{studentData?.testScore?.GMATExamDate}</div>
                  </div>
                  {otherExamDate <= today && (
                    <div className={classes["body-section-2"]}>
                      {studentData?.testScore?.GMATExam === 1 && (
                        <div className={classes["exam-scores"]}>
                          <div>English: <span className={classes["score"]}>{studentData?.testScore?.ACTScoreEnglish}</span></div>
                          <div>Math: <span className={classes["score"]}>{studentData?.testScore?.ACTScoreMath}</span></div>
                          <div>Science: <span className={classes["score"]}>{studentData?.testScore?.ACTScoreScience}</span></div>
                          <div>Reading: <span className={classes["score"]}>{studentData?.testScore?.ACTScoreReading}</span></div>
                          <div>Writing: <span className={classes["score"]}>{studentData?.testScore?.ACTScoreWriting}</span></div>
                        </div>
                      )}
                      {studentData?.testScore?.GMATExam === 2 && (
                        <div className={classes["exam-scores"]}>
                          <div>Verbal: <span className={classes["score"]}>{studentData?.testScore?.GREScoreV}</span></div>
                          <div>Quantitative: <span className={classes["score"]}>{studentData?.testScore?.GREScoreQ}</span></div>
                          <div>Analytical: <span className={classes["score"]}>{studentData?.testScore?.GREScoreW}</span></div>
                        </div>
                      )}
                      {studentData?.testScore?.GMATExam === 3 && (
                        <div className={classes["exam-scores"]}>
                          <div>Verbal: <span className={classes["score"]}>{studentData?.testScore?.GREScoreV}</span></div>
                          <div>Quantitative: <span className={classes["score"]}>{studentData?.testScore?.GREScoreQ}</span></div>
                          <div>Analytical: <span className={classes["score"]}>{studentData?.testScore?.GREScoreW}</span></div>
                        </div>
                      )}
                      {studentData?.testScore?.GMATExam === 4 && (
                        <div className={classes["exam-scores"]}>
                          <div>Math: <span className={classes["score"]}>{studentData?.testScore?.SATScoreMath}</span></div>
                          <div>Evidence Based Reading and Writing: <span className={classes["score"]}>{studentData?.testScore?.SATScoreEBRW}</span></div>
                        </div>
                      )}
                      {studentData?.testScore?.GMATExam !== 3 && (
                        <div className={classes["overall-score"]}>
                          <div>Total Score:</div>
                          {studentData?.testScore?.GMATExam === 1 && (
                            <div className={classes["score"]}>{studentData?.testScore?.ACTScoreTotal}</div>
                          )}
                          {studentData?.testScore?.GMATExam === 2 && (
                            <div className={classes["score"]}>{studentData?.testScore?.SATScoreTotal}</div>
                          )}
                          {studentData?.testScore?.GMATExam === 4 && (
                            <div className={classes["score"]}>{studentData?.testScore?.SATScoreTotal}</div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {studentData.testScore.OtherExamDocPath && studentData.testScore.OtherExamDocPath !== "" && (
                    <div className={classes["body-section-3"]}>
                      <div>Document:</div>
                      <div className={classes["document-file"]}>{studentData?.testScore?.OtherExamDocPath}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Documents;
